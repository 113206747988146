@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Montserrat,sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: transparent;
  background-image: linear-gradient(180deg,#077fc0,#60c0f6) ;
  background-repeat: no-repeat;
  color: #fff;
  min-height: 100vh; */
}

code {
  font-family: Montserrat,sans-serif;
}
.remove-scroll::-webkit-scrollbar{
  width: 0px;
}
.remove-scroll::-webkit-scrollbar{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.details-section .timeline::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0.32em;
  height: 100%;
  width: 2px;
  background-color: #60C0F6;
}
.details-section .timeline .timeline-item::before{
  content: '';
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: #60C0F6;
    top: 16px;
    left: 0;
    border-radius: 50%;
}
